<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.billing_id')"
            v-model="form.billing_id"
            :placeholder="$t('placeholder.enter_billing_id')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AppInput from '@/components/form-groups/AppInput';

export default {
  components: {
    AppInput
  },
  props: {
    complex: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      updatePageFlag: false,
      form: {
        name: null,
        billing_id: null
      }
    }
  },
  watch: {
    complex(data) {
      if (data) {
        this.updatePageFlag = true;

        this.form.name = data.name;
        this.form.billing_id = data.billing_id;
      }
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>